.header-title-subtitle {
  padding: 15px 0;
  text-align: center;

  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.3em;
  }

  .subtitle {
    font-size: 1.2em;
  }
}